import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import './scss/style.scss';
import './index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getObjectData } from './services/Storage';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const loading = (
   <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
   </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Forgot = React.lazy(() => import('./views/pages/Forgot/forgot'));
const PaymentIntent = React.lazy(() => import('./views/pages/payment/Payment'))
const SetPassword = React.lazy(() =>
   import('./views/pages/Forgot/setPassword'),
);
const LandingPage = React.lazy(() =>
   import('./views/pages/LandingPage/LandingPage'),
);
const ServiceStation = React.lazy(() =>
   import('./views/pages/ServiceStation/serviceStation'),
);
const ServiceStationDetails = React.lazy(() =>
   import('./views/pages/ServiceStation/serviceStationDetails'),
);
const Reviews = React.lazy(() =>
   import('./views/pages/ServiceStation/CustomerReview'),
);
const InspectionBooking = React.lazy(() =>
   import('./views/pages/inspectionBooking'),
);
const Blog = React.lazy(() => import('../src/views/pages/Blog/Blog'));

const BlogContent = React.lazy(() =>
   import('../src/views/pages/Blog/BlogContent'),
);
const MyBooking = React.lazy(() => import('../src/views/pages/myBookings'));
const UserPayment = React.lazy(() =>
   import('./views/pages/ServiceStation/Payment'),
);
const Privacy = React.lazy(() => import('./views/pages/Privacy/privacy'));
const TermOfUse = React.lazy(() => import('./views/pages/Privacy/termOfUse'));
const Notice = React.lazy(() => import('./views/pages/Privacy/notice'));
const ScheduleDetails = React.lazy(() =>
   import('./views/dashboard/ScheduleDetails'),
);

const MyBookingAppointment = React.lazy(() =>
   import('./views/pages/ServiceStation/BookAppointment'),
);

function App() {
   const stripePromise = loadStripe(
      'pk_test_51HIjtFHL3PuxjXaZm5knlYkjHYIEo7LYKmFkOCA1sxlLu97g59mFCgFguOtys6Oj2ubFDtGHY954VisYeWq4zqTJ00mAKwdHNj',
   );
   const options = {
      mode: 'payment',
      amount: 1099,
      currency: 'usd',
      // Fully customizable with appearance API.
      appearance: {
         /*...*/
      },
   };
   const [isAuthenticated, setIsAuthenticated] = useState(true);

   useEffect(() => {
      checkAuthState();
   }, []);

   const checkAuthState = async () => {
      let userDetail = await getObjectData('auth');
      let loggedIn = userDetail ? true : false;
      setIsAuthenticated(loggedIn);
   };

   const AuthenticatedRoutes = ({ render, isAuthenticated }) => {
      if (!isAuthenticated) {
         return <Navigate to="/login" />;
      }
      return render;
   };

   return (

      <div>
         <ToastContainer autoClose={1500} />
         <Elements stripe={stripePromise} options={options}>
            <BrowserRouter>
               <Suspense fallback={loading}>
                  <Routes>
                     <Route
                        exact
                        path="/login"
                        name="Login Page"
                        element={
                           <Login setIsAuthenticated={setIsAuthenticated} />
                        }
                     />
                     <Route
                        exact
                        path="/user/myBooking"
                        name="Login Page"
                        element={
                           <MyBooking setIsAuthenticated={setIsAuthenticated}/>
                        }
                     />
                     <Route 
                        path='/payment'
                        name="payment"
                        element={ <PaymentIntent/> }
                     />
                     <Route
                        exact
                        path="/register"
                        name="Register Page"
                        element={<Register />}
                     />
                     <Route
                        exact
                        path="/forgot"
                        name="Forgot Page"
                        element={<Forgot />}
                     />
                     <Route
                        exact
                        path="/set-password"
                        name="Passwrod Page"
                        element={<SetPassword />}
                     />
                     <Route
                        exact
                        path="/"
                        name="Landing Page"
                        element={<LandingPage />}
                     />
                     <Route
                        exact
                        path="/blog"
                        name="Landing Page"
                        element={<Blog />}
                     />

                     <Route
                        exact
                        path="/request-inspection"
                        name="Landing Page"
                        element={<MyBookingAppointment />}
                     />

                     <Route
                        exact
                        path="/privacy-policy"
                        name="Landing Page"
                        element={<Privacy />}
                     />
                     <Route
                        exact
                        path="/terms-of-use"
                        name="Landing Page"
                        element={<TermOfUse />}
                     />
                     <Route
                        exact
                        path="/booking-terms"
                        name="Landing Page"
                        element={<Notice />}
                     />
                     <Route
                        exact
                        path="/user/search-station"
                        name="SearchStation"
                        element={<ServiceStation />}
                     />
                     <Route
                        exact
                        path="/user/search-station/station-details/:id"
                        name="StationDetails"
                        element={<ServiceStationDetails />}
                     />
                     <Route
                        exact
                        path="/user/search-station/station-details/:id/customer-reviews"
                        name="CustomerReviews"
                        element={<Reviews />}
                     />
                     <Route
                        exact
                        path="user/request-inspection"
                        name="RequestInspection"
                        element={<MyBookingAppointment />}
                     />
                     <Route
                        exact
                        path="user/search-station/request-inspection/:id"
                        name="RequestInspection"
                        element={<MyBookingAppointment />}
                     />
                     <Route
                        exact
                        path="/user/inspection-booking"
                        name="inspectionBooking"
                        element={<InspectionBooking />}
                     />
                     <Route
                        exact
                        path="/user/blog"
                        name="blog"
                        element={<Blog />}
                     />
                     <Route
                        exact
                        path="/user/blog/:slug"
                        name="blogContent"
                        element={<BlogContent />}
                     />
                     {/* <Route
                        exact
                        path="/user/mybooking"
                        name="myBooking"
                        element={<MyBooking />}
                     /> */}
                     {/* <Route
                        exact
                        path="/user/mybooking/payment"
                        name="payment"
                        element={<UserPayment />}
                     /> */}
                     <Route
                        path="*"
                        name="Home"
                        element={
                           <AuthenticatedRoutes
                              render={<DefaultLayout />}
                              isAuthenticated={isAuthenticated}
                           />
                        }
                     />
                      <Route
                        element={<AuthenticatedRoutes 
                           isAuthenticated={isAuthenticated}
                        />}
                     >
                        {/* <Route
                           path="/user/mybooking"
                           element={<MyBooking />}
                        /> */}
                        <Route
                           path="/user/inspection-booking"
                           element={<InspectionBooking />}
                        />
                        <Route
                           path="*"
                           element={<DefaultLayout />}
                        />
                     </Route>
                  </Routes>
               </Suspense>
            </BrowserRouter>
         </Elements>
      </div>
   );
}

export default App;
